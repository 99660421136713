/* Main Ranges ----------------------------------- */

.main-range {
    float: left;
    width: 100%;
    color: #fff;
    font-weight: lighter;
    box-sizing: border-box;

    #event-timeline & {
        position: relative;
        height: 100%;
    }
}

.start-earth .space-range,
.start-humans .space-range,
.start-humans .geologic-range,
.end-earth .geologic-range,
.end-earth .human-range,
.end-humans .human-range {
    display: none;
}

#dark-ages {
    width: 1.0525765318722%;
    background-color: black;
}

#reionization {
    width: 9.4711551032385%;
    background-color: grey;
}

#stellar {
    width: 89.4762683648893%;
    background-color: darkblue;
}

.space-range {
    width: (9.2 / 13.8) * 100%;
}

.start-bang.end-humans .space-range {
    width: (9.2 / 13.7998) * 100%;
}

.geologic-range {
    width: (4.5998 / 13.8) * 100%;
    
    #range-timeline & {
        font-size: 0.75em;
        letter-spacing: 0.08em;
        background-color: darkblue;
    }
}

.start-bang.end-humans .geologic-range {
    width: (4.5998 / 13.7998) * 100%;
}

.start-earth.end-present .geologic-range {
    width: (4.5998 / 4.6) * 100%;
}

.human-range {
    width: (0.0002 / 13.8) * 100%;
}

.start-earth.end-present .human-range {
    width: (0.0002 / 4.6) * 100%;
}

.start-bang.end-earth .space-range,
.start-earth.end-humans .geologic-range,
.start-humans.end-present .human-range {
    width: 100%;
}

%range-box {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    &:active,
    &:hover {
        filter: brightness(1.3);
    }
}

.space {
    @extend %range-box;
    float: left;
    height: 100%;

    &.height-5 {
        height: 5vmin;
    }

    &.height-10 {
        height: 10vmin;
    }

    &.height-15 {
        height: 15vmin;
    }
}


/* Geologic Ranges ------------------------------- */

.eon-range {
    float: left;
}

.eras-range {
    display: flex;
    width: 100%;
}

.era-range {
    display: flex;
    flex-direction: column;
}

.eon,
.era,
.period {
    @extend %range-box;
    width: 100%;
    height: 5vmin;
    overflow: hidden;
}

.periods-range {
    display: flex;
}

/*
.eon::after {
    content: '\00a0 eon';
}

.era::after {
    content: '\00a0 era';
}

.period::after {
    content: '\00a0 period';
}
*/


/* Eon Ranges ------------------------------------ */

#hadean-range {
    width: 0.6 / 4.6 * 100%;
}

#hadean {
    background-color: #e00;     /* red */
}

#archaen-range {
    width: 1.5 / 4.6 * 100%;
}

#archaen {
    background-color: #800080;  /* purple */
}

#proterozoic-range {
    width: 1.959 / 4.6 * 100%;
}

#proterozoic {
    background-color: #ee9a00;  /* orange */
}

#phanerozoic-range {
    width: 0.541 / 4.6 * 100%;
}

#phanerozoic {
    background-color: #008000;  /* green */
}
