/* Geologic Eras --------------------------------- */

/* Archaen Eon */

#archaen-eras .era {
    border-right: 1px dashed #800080;
}

#eoarchaen-range {
    width: 26.666666%;
}

#eoarchaen {
    background-color: #a200a2;
}

#paleoarchaen-range {
    width: 26.666666%;
}

#paleoarchaen {
    background-color: #b300b3;
}

#mesoarchaen-range {
    width: 26.666666%;
}

#mesoarchaen {
    background-color: #c400c4;
}

#neoarchaen-range {
    width: 20%;
}

#neoarchaen {
    background-color: #d500d5;
}


/* Proterozoic Eon */

#proterozoic-eras .era {
    border-right: 1px dashed #ee9a00;
}

#paleoproterozoic-range {
    width: 46.153846%;
}

#paleoproterozoic {
    background-color: #ffab11;
}

#mesoproterozoic-range {
    width: 30.769231%;
}

#mesoproterozoic {
    background-color: #ffb733;
}

#neoproterozoic-range {
    width: 23.076923%;
}

#neoproterozoic {
    background-color: #ffc355;
}


/* Phanerozoic Eon */

#phanerozoic-eras .era {
    border-right: 1px dashed #008000;
}

#paleozoic-range {
    width: 54.545455%;
}

#paleozoic {
    background-color: #00a200;
}

#mesozoic-range {
    width: 33.636364%;
}

#mesozoic {
    background-color: #00c400;
}

#cenozoic-range {
    width: 11.818181%;
}

#cenozoic {
    background-color: #00e600;
}
