/* Options --------------------------------------- */

#options {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 3;
    width: 100%;
    max-width: 720px;
    max-height: 100%;
    height: 2.2rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 0 3px #aaa;
    box-sizing: border-box;

    .show-effects & {
        transition: height 500ms;
    }

    &.show-menu {
        height: inherit;
    }

    @media (min-width: 481px) {
        top: 2vmin;
        width: 90vw;
        max-height: calc(100% - 2vmin);
        border-radius: 2px;
    }
}

#options-bar {
    display: flex;
    flex: 0 0 2.2rem;
    align-items: center;
    z-index: 1;
    height: 2.2rem;
    padding: 5px;
    background-color: #fff;
    box-sizing: border-box;

    .show-effects & {
        transition: background-color 500ms;
    }
    
    .show-menu & {
        background-color: #efefef;
    }

    @media (min-width: 481px) {
        border-radius: 2px;
    }
}
