/* Notice ---------------------------------------- */

#notice {
    position: fixed;
    bottom: -2.2rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 3;
    width: 100%;
    height: 2.2rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    letter-spacing: 0.01em;
    color: #efefef;
    background-color: black;
    box-shadow: 0 0 3px #aaa;
    box-sizing: border-box;
    cursor: pointer;

    .show-effects & {
        transition: transform 500ms;
    }

    &.show-notice {
        transform: translateY(-2.2rem);

        @media (min-width: 481px) {
            transform: translateY(calc(-2.2rem - 2vmin));
        }
    }

    @media (min-width: 401px) {
        font-size: 1rem;
    }

    @media (min-width: 481px) {
        width: 24rem;
        max-width: 90vw;
        border-radius: 2px;
    }
}
