/* Miscellaneous --------------------------------- */

body {
    margin: 0 5vw;
    overflow-x: visible;
    overflow-y: hidden;
    font: lighter 18px Helvetica, Arial, sans-serif;
    background: #edebe1 url('images/concrete.png');
    -webkit-tap-highlight-color: transparent;
}

.hide {
    display: none !important;
}

.last {
    border-right: none !important;
}

i {
    font-style: normal;
    text-transform: none;
}

.truncate i {
    display: none;
}

input[type="checkbox"],
input[type="radio"] {
    margin: 0 0.5rem 0 0.2rem;
}

:focus,
::-moz-focus-inner,
::-moz-focus-outer,
input[type="range"] {
    outline: none;
    border: none;
    background: none;
}
