/* Scale ----------------------------------------- */

#scale {
    display: flex;
    flex-grow: 1;
    align-items: center;
    height: 100%;

    label {
        display: none;
        align-items: center;
        padding: 0 0.5rem;
        height: 100%;
        font-size: 0.9rem;

        &.active {
            cursor: default;
            pointer-events: none;
        }

        @media (min-width: 601px) {
            display: flex;
        }
    }
}

#scale-slider {
    width: 100%;
    height: 100%;
    margin: 0 0.25rem;
    cursor: pointer;
}
