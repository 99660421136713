/* Labels ---------------------------------------- */

label {
    color: #777;
    letter-spacing: 0.02em;
    white-space: nowrap;
    cursor: pointer;

    .show-effects & {
        transition: color 80ms, text-shadow 80ms;
    }
    
    &.active,
    &:active,
    &:hover {
        color: #000;
        text-shadow: 0 0 1px #ffc355;
    }
}
