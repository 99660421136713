/* Timelines ------------------------------------- */

#timelines {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    height: 100vh;
    /* overflow-y: hidden; */
    background-size: 6.52173913vw 100%;

    &.show-lines {
        background-image: linear-gradient(to right, #d3d3d3 0, transparent 1px);
    }
}

.timeline {
    width: 100%;
    white-space: nowrap;
    background: black url('images/twinkle.png');
    box-shadow: 0 0 4px #777;
}

#range-timeline {
    display: none;
    margin-bottom: 3vmin;
    font-size: 3vmin;
    font-variant: small-caps;
    letter-spacing: 0.08em;
    text-align: center;
    text-transform: capitalize;

    &.show-ranges {
        display: flex;
    }
}

#event-timeline {
    display: none;
    height: 25vmin;
    max-height: 800px;

    &.show-events {
        display: block;
    }
}

#shadow {
    position: fixed;
    top: 1.5rem;
    left: 0;
    z-index: 2;
    width: 100%;
    height: calc(100% - 1.5rem);
    pointer-events: none;
    box-shadow: 0 0 10vmin #272100 inset;

    @media (min-width: 481px) {
        top: 0;
        height: 100%;

        .show-effects & {
            transition: box-shadow 500ms;

            &.dark {
                box-shadow: 0 0 40vmax #272100 inset;
            }
        }
    }
}
