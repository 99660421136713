/* Ruler ----------------------------------------- */

#ruler {
    position: fixed;
    top: 3.5rem;
    display: none;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    width: 90vw;
    height: 2rem;
    color: #aaa;
    border-left: 1px solid #888;
    border-right: 1px solid #888;
    box-sizing: border-box;

    &.show-ruler {
        display: flex;
    }
}

%ruler-dots {
    content: '';
    width: 50vw;
    border-bottom: 2px dotted #aaa;
}

#ruler-label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
    font-size: 0.8rem;
    font-weight: bolder;
    letter-spacing: 0.03em;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    cursor: move;

    &::before {
        @extend %ruler-dots;
        margin-right: 0.5rem;
    }

    &::after {
        @extend %ruler-dots;
        margin-left: 0.5rem;
    }
}

.resize-handle {
    display: flex;
    align-items: center;
    cursor: ew-resize;

    img {
        width: 2rem;
        pointer-events: none;
    }
    
    &:last-of-type img {
        transform: scaleX(-1);
    }
}
