/* Events ---------------------------------------- */

.event {
    position: absolute;
    top: 0;
    height: 100%;
}

.mask {
    position: absolute;
    top: 0;
    left: 2px;
    z-index: 1;
    height: 100%;
    opacity: 0.8;
    background: url('images/mask.svg') 0 0 / cover no-repeat;
}

.mask + .event-image {
    filter: brightness(1.1) saturate(1.1);
}

.event-image {
    /* min-width: 10px; */
    height: 100%;
    border-left: 2px dashed #333;
}

.start-bang #space-events .event:first-of-type .event-image,
.start-earth #geologic-events .event:first-of-type .event-image,
.start-humans #human-events .event:first-of-type .event-image {
    border-left: none;
}

.desc {
    max-width: 13em;
    margin-top: -0.25em;
    padding: 0.5vh 1.5vh 0;
    color: #555;
    letter-spacing: 0.01em;
    line-height: 1.4;
    white-space: normal;
    border-left: 2px solid #555;
    background: #edebe1 url('images/concrete.png');
    box-shadow: 1px 4px 4px -4px #777 inset;

    h2 {
        margin: 2vh 0;
        color: #333;
        font-size: 1.3em;
        letter-spacing: 0.03em;
        line-height: 1.2;
        text-transform: uppercase;
    }

    h4 {
        margin: 2vh 0;
        color: #333;
        letter-spacing: 0.04em;
        font-variant: small-caps;
        font-weight: lighter;
        font-size: 1rem;
        text-transform: capitalize;
    }

    p {
        display: none;
        margin: 1vh 0;
        font-size: 0.85rem;
    }

    .show-desc & p:first-of-type {
        display: inherit;
    }
}


/* Space */

#big-bang {
    left: 0;
}

#milky-way {
    left: 30.614%;
}

#solar-system {
    left: 95.3%;
}


/* Geologic */

#earth {
    left: 0;
}

#simple-life {
    left: 0.6 / 4.6 * 100%;
}

#photosynthesis {
    left: 23.9%;
}

#eukaryotes {
    left: 2.1 / 4.6 * 100%;
}

#complex-life {
    left: 78.2%;
}

#land-life {
    left: 86.96%;
}

#dinosaurs {
    left: 93.49%;
}

#mammals {
    left: 95.1%;
}

#primates {
    left: 98.6%;
}

#great-apes {
    left: 99.7%;
}

#homo {
    left: 99.9425%;
}
