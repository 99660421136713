/* Options Menu ---------------------------------- */

#options-menu {
    display: flex;
    justify-content: start;
    flex-direction: column;
    overflow-y: auto;
    padding-top: 0.5rem;
    font-size: 1.2rem;
    border-top: 1px solid #d3d3d3;

    @media (min-width: 481px) {
        justify-content: space-around;
        padding-top: 0.25rem;
        overflow-y: hidden;
        font-size: 1rem;
    }
}

form {
    display: flex;
    justify-content: space-between;
}

fieldset {
    display: flex;
    flex-direction: column;
    margin: 0;
    border: none;

    label {
        display: flex;
        align-items: center;
        padding: 0.25rem 0;
    }
}

legend {
    width: 100%;
    padding: 0.75rem 0 0.25rem;
    font-weight: bolder;
    font-variant: small-caps;
    letter-spacing: 0.04em;

    #miscellaneous & {
        padding-top: 1rem;
        border-top: 1px solid #d3d3d3;
    }
}

#timeline-options {
    flex-wrap: wrap;

    @media (min-width: 481px) {
        flex-wrap: nowrap;
    }

    fieldset:first-of-type {
        flex-basis: 100%;

        @media (min-width: 481px) {
            flex-basis: auto;
        }
    }
}

#misc-options {
    flex-direction: column;

    @media (min-width: 481px) {
        flex-direction: row;
    }
}

#miscellaneous {
    flex: 0 1 47.5%;
}

#about {
    margin-left: 0;
    line-height: 1.45em;
    letter-spacing: 0.02em;

    @media (min-width: 481px) {
        margin-left: 1rem;
    }

    p {
        color: #777;
        margin: 0;
    }
}

#menu-button {
    min-width: 1.13rem;
    height: 100%;
    margin: 0 0.5rem 0 0.3rem;
    border: none;
    background-color: transparent;
    background-image: url('images/menu.svg');
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
    outline: none;
}
