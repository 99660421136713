/* Geologic Periods ------------------------------ */

/* Paleoproterozoic Era */

#paleoproterozoic-periods .period {
    border-right: 1px dashed #ffab11;
}

#siderian {
    width: 22.222222%;
    background-color: #aa6e00;
}

#rhyacian {
    width: 27.777778%;
    background-color: #bb7900;
}

#orosirian {
    width: 27.777778%;
    background-color: #cc8400;
}

#statherian {
    width: 22.222222%;
    background-color: #dd8f00;
}


/* Mesoproterozoic Era */

#mesoproterozoic-periods > .period {
    border-right: 1px dashed #ffb733;
}

#calymmian {
    width: 33.333334%;
    background-color: #ee9a00;
}

#ectasian {
    width: 33.333333%;
    background-color: #ffa500;
}

#stenian {
    width: 33.333333%;
    background-color: #ffab11;
}


/* Neoproterozoic Era */

#neoproterozoic-periods > .period {
    border-right: 1px dashed #ffc355;
}

#tonian {
    width: 62.222222%;
    background-color: #ffb122;
}

#cryogenian {
    width: 17.777778%;
    background-color: #ffb733;
}

#ediacaran {
    width: 20%;
    background-color: #ffbd44;
}


/* Paleozoic Era */

#paleozoic-periods .period {
    border-right: 1px dashed #00a200;
}

#cambrian {
    width: 20%;
    background-color: #003c00;
}

#ordovician {
    width: 15%;
    background-color: #004d00;
}

#silurian {
    width: 10%;
    background-color: #005e00;
}

#devonian {
    width: 18.333333%;
    background-color: #006f00;
}

#carboniferous {
    width: 20%;
    background-color: #008000;
}

#permian {
    width: 16.666667%;
    background-color: #009100;
}


/* Mesozoic Era */

#mesozoic-periods .period {
    border-right: 1px dashed #00c400;
}

#triassic {
    width: 27.027027%;
    background-color: #00a200;
}

#jurassic {
    width: 29.72973%;
    background-color: #00b300;
}

#cretaceous {
    width: 43.243243%;
    background-color: #00c400;
}


/* Cenozoic Era */

#cenozoic-periods .period {
    border-right: 1px dashed #00e600;
}

#paleogene {
    width: 64.615385%;
    background-color: #00d500;
}

#neogene {
    width: 31.282051%;
    background-color: #00e600;
}

#quaternary {
    width: 4.102564%;
    background-color: #00f700;
}
